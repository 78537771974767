
    .exam-paper-content {
		* {
			box-sizing: border-box;
		}
		height: 100%;
        width: 100%;
        .ep-acts {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
			padding: 10px;
            .class-tit, .classes-list {
                margin-right: 5px;
            }
            .ep-act-search {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .search-btns {
                    width: 300px;
                }
            }
        }
    }
	.act-btns {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.ep-list {
		height: calc(100% - 60px);
		.table-data {
			height: calc(100% - 42px);
			::v-deep .el-scrollbar__wrap {
				overflow-x: hidden;
			}
		}
		.page-block {
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

